import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { api } from 'services';
import { notificationContainer } from 'services/utils/notificationContainer';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import axios from 'axios';
import { errorText } from 'services/utils/strings';
import {
	authInitState,
	loginRequest,
	loginSuccess,
	logoutRequest,
	refreshTokenRequest,
	refreshTokenSuccess,
	resetPasswordRequest,
	createPasswordRequest,
	getUserSuccess,
	getUserRequest,
	getTwoFaStatusRequest,
	resetTwoFaRequest,
	generateCodeForTwoFaRequest,
	hideLoader,
	loginRequestStep1,
} from './reducer';
import {
	ILoginResponse,
	ILoginPayload,
	IResetPasswordPayload,
	ICreateNewPasswordPayload,
	IResetTwoFaRequestPayload,
	ICodeForTwoFaRequest,
	ICodeForTwoFaResponse,
	ILoginStep1Payload,
} from './types';

import { popUpOpen } from '../popUp/reducer';
import { notificationsMessagesInfo } from '../../../services/utils/notificationsMessages/notificationsMessagesInfo';
import { twoSuccess } from '../twoFa/reducer';
import { clearError, setErrorMsg } from '../errors/reducer';

function* loginRequestStep1Worker({ payload }: PayloadAction<ILoginStep1Payload>) {
	const { apiParams, onFinally } = payload;
	let errorMsg = null;
	try {
		yield put(showLoading());
		yield put(clearError());
		const response: ILoginResponse = yield call(api.auth.login, apiParams.data);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			errorMsg = error?.response?.data?.errors;
		}
		yield put(authInitState());
	} finally {
		yield put(hideLoader());
		onFinally?.(errorMsg);
	}
}
function* loginRequestWorker(action: PayloadAction<ILoginPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		yield put(clearError());
		const response: ILoginResponse = yield call(api.auth.login, payload.data);
		// if (response?.user_data?.status?.name === 'pending') {
		// 	notificationContainer(
		// 		'Your account has not been verified. You will receive an email once completed.',
		// 		'user_blocked',
		// 		'Account Pending Verification',
		// 	);
		// } else
		if (response?.user_data?.status?.name === 'rejected') {
			notificationContainer('', 'user_rejected', 'Verification rejected');
			// } else if (response?.user_data?.google2fa_enabled === 0) {
			// 	notificationContainer('Please enable 2FA Code', 'error', '2FA');
		} else {
			notificationContainer(notificationsMessagesInfo.loginSuccess, 'success', 'Welcome back!');
			yield put(loginSuccess(response));
			if (response?.user_data?.google2fa_enabled === 0) {
				payload.history.push('/security-settings');
			} else {
				payload.history.push('/trade');
			}
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			yield put(setErrorMsg(error.response?.data.errors[0]));
			// console.log(error.response?.data.errors);
		}
	} finally {
		yield put(hideLoader());
		yield put(hideLoading());
	}
}

function* logoutRequestWorker(action: PayloadAction<ILoginPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		yield call(api.auth.logout);
		notificationContainer(notificationsMessagesInfo.logout, 'success', 'You have been logged out.');
		payload.history.push('/login');
		yield put(authInitState());
	} catch (error) {
		yield put(authInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* refreshTokenWorker() {
	try {
		yield put(showLoading());
		const response: ILoginResponse = yield call(api.auth.refreshToken);
		yield put(refreshTokenSuccess(response));
	} catch (error) {
		yield put(authInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* resetPasswordWorker(action: PayloadAction<IResetPasswordPayload>) {
	try {
		yield put(clearError());
		yield put(showLoading());
		yield call(api.auth.resetPassword, action.payload);
		yield put(popUpOpen('resetPasswordSuccess'));
	} catch (error) {
		yield put(authInitState());
		if (axios.isAxiosError(error)) {
			// notificationContainer(error.response?.data.errors[0], 'error');
			// console.log(error?.response?.data?.errors);
		}
	} finally {
		yield put(hideLoading());
	}
}
function* createPasswordWorker(action: PayloadAction<ICreateNewPasswordPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		yield call(api.auth.newPassword, action.payload.data);
		// payload.history.push('/login');
		yield put(popUpOpen('newPasswordSuccess'));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			// notificationContainer(error.response?.data.errors[0], 'error');
			// console.log(error?.response?.data?.errors);
		}
		yield put(authInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* getUserWorker() {
	try {
		const response: ILoginResponse = yield call(api.auth.getUser);
		yield put(getUserSuccess(response.user_data));
	} catch (error) {
		// notificationContainer('Error', 'error');
	}
}

function* getTwoFaStatusWorker(action: any) {
	const { payload } = action;
	try {
		const response: { status: string } = yield call(api.settings.getTwoFaStatus);
		if (response.status === '2fa_enabled') {
			payload.setTwoFaStatus(true);
		}
	} catch (error) {
		// notificationContainer('Error', 'error');
	}
}

function* reset2FAWorker({ payload }: PayloadAction<IResetTwoFaRequestPayload>) {
	try {
		yield put(clearError());
		yield call(api.auth.resetTwoFa, payload);
		yield put(popUpOpen('resetTwoFaSuccess'));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			// notificationContainer(errorText(error.response?.data.errors[0]), 'error');
			// console.log(error.response);
		}
	}
}

function* getCodeForTwoFaWorker({ payload }: PayloadAction<ICodeForTwoFaRequest>) {
	try {
		const response: ICodeForTwoFaResponse = yield call(api.auth.resetTwoFaCode, payload);
		yield put(twoSuccess(response));
		// eslint-disable-next-line no-empty
	} catch (error) {
		if (axios.isAxiosError(error)) {
			// notificationContainer(errorText(error.response?.data.errors[0]), 'error');
			// console.log(error?.response?.data.errors[0]);
			notificationContainer(
				'You have already reset your 2FA.<br> Please wait for the request approval of contact support for more details',
				'user_blocked',
				'Error',
			);
		}
	}
}

// =============================================================:
export function* authSaga() {
	yield takeEvery(loginRequest.type, loginRequestWorker);
	yield takeEvery(loginRequestStep1.type, loginRequestStep1Worker);
	yield takeEvery(logoutRequest.type, logoutRequestWorker);
	yield takeEvery(refreshTokenRequest.type, refreshTokenWorker);
	yield takeEvery(resetPasswordRequest.type, resetPasswordWorker);
	yield takeEvery(createPasswordRequest.type, createPasswordWorker);
	yield takeEvery(getUserRequest.type, getUserWorker);
	yield takeEvery(getTwoFaStatusRequest.type, getTwoFaStatusWorker);
	yield takeEvery(resetTwoFaRequest, reset2FAWorker);
	yield takeEvery(generateCodeForTwoFaRequest, getCodeForTwoFaWorker);
}
