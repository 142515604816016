import React, { FC, useEffect, useMemo, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { IWalletItem } from 'redux/reducers/wallets/types';
import { IWalletsTable } from 'components/Wallets/WalletsTable/type';
import WalletsTableItem from 'components/Wallets/WalletsTable/WalletsTableItem/WalletsTableItem';
import WalletsTableHeader from 'components/Wallets/WalletsTable/WalletsTableHeader/WalletsTableHeader';

const WalletsTable: FC<IWalletsTable> = ({ title, walletsList, type }) => {
	const [sortMagnitude, setSortMagnitude] = useState<string>('desc');
	const [sortType, setSortType] = useState<string>('balance_eur');

	const sortedWalletsList = useMemo(() => {
		if (!walletsList) return [];

		if (sortMagnitude === 'asc') {
			return [...walletsList].sort((a, b) => {
				const sortFirstItem =
					sortType === 'total'
						? a.balance + a.frozen_balance
						: a.asset[sortType as keyof typeof a.asset] || Number(a[sortType as keyof typeof a]);

				const sortSecondItem =
					sortType === 'total'
						? b.balance + b.frozen_balance
						: b.asset[sortType as keyof typeof b.asset] || Number(b[sortType as keyof typeof b]);

				return sortFirstItem > sortSecondItem ? 1 : -1;
			});
		}

		if (sortMagnitude === 'desc') {
			return [...walletsList].sort((a, b) => {
				const sortFirstItem =
					sortType === 'total'
						? a.balance + a.frozen_balance
						: a.asset[sortType as keyof typeof a.asset] || Number(a[sortType as keyof typeof a]);

				const sortSecondItem =
					sortType === 'total'
						? b.balance + b.frozen_balance
						: b.asset[sortType as keyof typeof b.asset] || Number(b[sortType as keyof typeof b]);

				return sortFirstItem < sortSecondItem ? 1 : -1;
			});
		}

		return walletsList;
	}, [walletsList, sortMagnitude, sortType]);

	return (
		<div>
			<div className="table-title">
				<p>{title}</p>
			</div>
			<div className="table-block table-block--mt-0">
				<div className="table-wrapper">
					<div className="table table--wallet table--fiat-balance">
						<WalletsTableHeader
							type={type}
							sortType={sortType}
							setSortType={setSortType}
							sortMagnitude={sortMagnitude}
							setSortMagnitude={setSortMagnitude}
						/>
						<div className="table-body">
							{!!sortedWalletsList?.length &&
								sortedWalletsList.map((wallet) => <WalletsTableItem key={wallet.id} {...wallet} />)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default WalletsTable;
