import React, { useEffect, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { navList } from 'routes/routesList';
import IconSvg from 'ui/Svg/IconSvg';
import Logo from 'assets/img/sidebar_logo_gray.svg';
import MinimizedLogo from 'assets/img/sidebar_minimized_logo.svg';
import MobileLogo from 'assets/img/sidebar_mobile_logo.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getMinimizedSidebarState } from 'redux/reducers/sidebar/selectors';
import { toggleMinimized } from 'redux/reducers/sidebar/reducer';
// import { getTwoFaStatusRequest, logoutRequest } from '../../../redux/reducers/auth/reducer';
import { logoutRequest } from '../../../redux/reducers/auth/reducer';
import { statusNames } from '../../Verification/drop-file-input/utiils';
import { getUserData } from '../../../redux/reducers/auth/selectors';

const WalletSideBar = () => {
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();
	const userData = useSelector(getUserData);
	const minimizedSidebar = useSelector(getMinimizedSidebarState);
	// const [status, setTwoFaStatus] = useState(false);
	// const google2faEnabled = useSelector((state: any) => state.userData.google2fa_enabled);

	const getNavLinkClass = (path: string[]) => {
		if (path.indexOf(location.pathname) !== -1) {
			return 'sidebar-nav-item__link active';
		}
		return 'sidebar-nav-item__link';
	};

	const closeAllMenus = () => {
		document
			.querySelectorAll('.sidebar-nav-item__link')
			.forEach((el) => el.classList.remove('is-opened'));
	};

	const submenuParentClickHandler = (event: any) => {
		const target = event.target.closest('.sidebar-nav-item__link');
		if (target.classList.contains('is-opened')) {
			target.classList.remove('is-opened');
		} else {
			target.classList.add('is-opened');
		}
	};

	const handleSidebarToogle = () => dispatch(toggleMinimized());

	// useEffect(() => {
	// 	document.addEventListener('click', (event: any) => {
	// 		const arr = Array.from(
	// 			document.querySelectorAll('.sidebar-nav-item__list-item--has-children'),
	// 		);
	// 		const insideBox = arr.some((el) => el.contains(event.target));
	// 		if (!insideBox) {
	// 			closeAllMenus();
	// 		}
	// 	});
	// }, []);

	// useEffect(() => {
	// 	dispatch(getTwoFaStatusRequest({ setTwoFaStatus }));
	// }, [dispatch]);

	// useEffect(() => {
	// 	console.log(userData?.status?.name);
	// }, [userData]);

	return (
		<div className={minimizedSidebar ? 'sidebar-nav sidebar-nav--minimized' : 'sidebar-nav'}>
			<button
				type="button"
				className={minimizedSidebar ? 'sidebar-btn minimized' : 'sidebar-btn'}
				onClick={handleSidebarToogle}
			>
				<svg
					width="16"
					height="16"
					viewBox="0 0 16 16"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M5.42667 8.32002C5.21333 8.16002 5.21333 7.84002 5.42667 7.68002L8.36 5.48002C8.62369 5.28225 9 5.4704 9 5.80002L9 10.2C9 10.5296 8.62369 10.7178 8.36 10.52L5.42667 8.32002Z"
						fill="#626A85"
					/>
				</svg>
			</button>
			<div className="sidebar-nav__left">
				<Link to="/" className="sidebar-nav__logo">
					<img src={minimizedSidebar ? MinimizedLogo : Logo} alt="" />
				</Link>
				<a href="/" className="sidebar1-nav__mobile-logo">
					<img src={MobileLogo} alt="" />
				</a>
				<div id="main_nav" className="sidebar-nav-item">
					<div className="sidebar-nav-item__header">
						<a href="/" className="sidebar1-nav__mobile-logo">
							<img src={MobileLogo} alt="" />
						</a>
						<div
							className="sidebar-nav-item__close"
							onClick={() => {
								const el = document.getElementById('main_nav');
								if (el) el.style.display = 'none';
							}}
						>
							<svg
								width="32"
								height="32"
								viewBox="0 0 32 32"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="#8D99C5" />
								<rect
									x="12.1113"
									y="11.4"
									width="12"
									height="1"
									rx="0.5"
									transform="rotate(45 12.1113 11.4)"
									fill="#626A85"
								/>
								<rect
									x="20.5967"
									y="12.1071"
									width="12"
									height="1"
									rx="0.5"
									transform="rotate(135 20.5967 12.1071)"
									fill="#626A85"
								/>
							</svg>
						</div>
					</div>

					<nav className="sidebar-nav-item__list">
						{!!userData && (
							<>
								{statusNames[userData?.status?.name] === 'Unverified' ||
								statusNames[userData?.status?.name] === 'Pending' ||
								statusNames[userData?.status?.name] === 'Rejected' ? (
									<ul>
										<li className="sidebar-nav-item__list-item sidebar-nav-item__list-item--has-children">
											<NavLink
												to="/account-settings"
												className={getNavLinkClass([
													'/verification',
													'/security-settings',
													'/referrals',
													'/security-settings/2fa',
												])}
												onClick={
													location.pathname.includes('account-settings') ||
													location.pathname.includes('verification')
														? submenuParentClickHandler
														: () => null
												}
											>
												<div className="sidebar-nav-item__icon sidebar-nav-item__icon--account-settings" />
												<div className="sidebar-nav-item__text">
													<p>Account Settings</p>
												</div>
											</NavLink>
											<div className="sidebar-nav-item__submenu">
												<ul>
													{!!userData && (
														<>
															{statusNames[userData?.status_id] !== 'Verified' && (
																<li>
																	<NavLink
																		to="/verification"
																		className={getNavLinkClass(['/verification'])}
																		activeClassName=""
																	>
																		<div className="sidebar-nav-item__icon sidebar-nav-item__icon--verification" />
																		<div className="sidebar-nav-item__text">
																			<p>Verification</p>
																		</div>
																	</NavLink>
																</li>
															)}
														</>
													)}
													<li>
														<button
															onClick={() => {
																dispatch(logoutRequest({ history }));
															}}
															type="button"
															className="sidebar-nav-item__link"
														>
															<div className="sidebar-nav-item__icon sidebar-nav-item__icon--logout" />
															<div className="sidebar-nav-item__text">
																<p>Log Out</p>
															</div>
														</button>
													</li>
												</ul>
											</div>
										</li>
									</ul>
								) : (
									<>
										{!userData?.google2fa_enabled ? (
											<ul className="not google2fa_enabled">
												<li className="sidebar-nav-item__list-item sidebar-nav-item__list-item--has-children">
													<NavLink
														to="/security-settings"
														className={getNavLinkClass([
															'/verification',
															'/security-settings',
															'/referrals',
															'/security-settings/2fa',
														])}
														onClick={
															location.pathname.includes('account-settings')
																? submenuParentClickHandler
																: () => null
														}
													>
														<div className="sidebar-nav-item__icon sidebar-nav-item__icon--account-settings" />
														<div className="sidebar-nav-item__text">
															<p>Account Settings</p>
														</div>
													</NavLink>
													<div className="sidebar-nav-item__submenu">
														<ul>
															<li>
																<NavLink
																	to="/security-settings"
																	className={getNavLinkClass([
																		'/security-settings',
																		'/security-settings/2fa',
																	])}
																	activeClassName=""
																>
																	<div className="sidebar-nav-item__icon sidebar-nav-item__icon--security-settings" />
																	<div className="sidebar-nav-item__text">
																		<p>Security</p>
																	</div>
																</NavLink>
															</li>
															<li>
																<button
																	onClick={() => {
																		dispatch(logoutRequest({ history }));
																	}}
																	type="button"
																	className="sidebar-nav-item__link"
																>
																	<div className="sidebar-nav-item__icon sidebar-nav-item__icon--logout" />
																	<div className="sidebar-nav-item__text">
																		<p>Log Out</p>
																	</div>
																</button>
															</li>
														</ul>
													</div>
												</li>
											</ul>
										) : (
											<ul>
												<li>
													<NavLink to={navList.wallets.path} activeClassName="active">
														<div className="sidebar-nav-item__icon sidebar-nav-item__icon--wallets" />
														<div className="sidebar-nav-item__text">
															<p>Wallets</p>
														</div>
													</NavLink>
												</li>
												<li>
													<NavLink to={navList.trade.path} activeClassName="active">
														<div className="sidebar-nav-item__icon sidebar-nav-item__icon--trade" />
														<div className="sidebar-nav-item__text">
															<p>Trade</p>
														</div>
													</NavLink>
												</li>
												<li className="sidebar-nav-item__list-item sidebar-nav-item__list-item--has-children">
													<div
														className={
															location.pathname === navList.depositFiat.path ||
															location.pathname === navList.withdrawFiat.path
																? 'sidebar-nav-item__link active is-opened'
																: getNavLinkClass([
																		navList.depositFiat.path,
																		navList.depositCrypto.path,
																		navList.withdrawFiat.path,
																		navList.withdrawCripto.path,
																  ])
														}
														onClick={submenuParentClickHandler}
													>
														<div className="sidebar-nav-item__icon sidebar-nav-item__icon--payments" />
														<div className="sidebar-nav-item__text">
															<p>Payments</p>
														</div>
													</div>
													<div className="sidebar-nav-item__submenu">
														<ul>
															<li>
																<NavLink
																	to={navList.depositFiat.path}
																	className={getNavLinkClass([
																		navList.depositFiat.path,
																		navList.depositCrypto.path,
																	])}
																	activeClassName=""
																>
																	<div className="sidebar-nav-item__icon sidebar-nav-item__icon--deposit" />
																	<div className="sidebar-nav-item__text">
																		<p>Deposit</p>
																	</div>
																</NavLink>
															</li>
															<li>
																<NavLink
																	to={navList.withdrawFiat.path}
																	className={getNavLinkClass([
																		navList.withdrawFiat.path,
																		navList.withdrawCripto.path,
																	])}
																	activeClassName=""
																>
																	<div className="sidebar-nav-item__icon sidebar-nav-item__icon--withdrawal" />
																	<div className="sidebar-nav-item__text">
																		<p>Withdrawal</p>
																	</div>
																</NavLink>
															</li>
														</ul>
													</div>
												</li>
												<li>
													<NavLink to={navList.paymentMethods.path} activeClassName="active">
														<div className="sidebar-nav-item__icon sidebar-nav-item__icon--beneficiaries" />
														<div className="sidebar-nav-item__text">
															<p>Beneficiaries</p>
														</div>
													</NavLink>
												</li>
												{/* <li> */}
												{/*	<NavLink to={navList.transactionHistory.path} activeClassName="active"> */}
												{/*		<div className="sidebar-nav-item__icon sidebar-nav-item__icon--wallet" /> */}
												{/*		<div className="sidebar-nav-item__text"> */}
												{/*			<p>Transaction History</p> */}
												{/*		</div> */}
												{/*	</NavLink> */}
												{/* </li> */}
												<li className="sidebar-nav-item__list-item sidebar-nav-item__list-item--has-children">
													<div
														className={
															location.pathname.includes('/history')
																? 'sidebar-nav-item__link active is-opened'
																: getNavLinkClass([
																		'/history/trade',
																		'/history/deposit-crypto',
																		'/history/deposit-fiat',
																		'/history/withdrawal-crypto',
																		'/history/withdrawal-fiat',
																  ])
														}
														onClick={submenuParentClickHandler}
													>
														<div className="sidebar-nav-item__icon sidebar-nav-item__icon--history" />
														<div className="sidebar-nav-item__text">
															<p>History</p>
														</div>
													</div>
													<div className="sidebar-nav-item__submenu">
														<ul>
															<li>
																<NavLink
																	to={navList.transactionsTrade.path}
																	className={getNavLinkClass(['/history/trade'])}
																	activeClassName=""
																>
																	<div className="sidebar-nav-item__icon sidebar-nav-item__icon--trade-history" />
																	<div className="sidebar-nav-item__text">
																		<p>Trade History</p>
																	</div>
																</NavLink>
															</li>
															<li>
																<NavLink
																	to={`${navList.depositsHistory.path}/deposit-crypto`}
																	className={getNavLinkClass([
																		'/history/deposit-crypto',
																		'/history/deposit-fiat',
																	])}
																	activeClassName=""
																>
																	<div className="sidebar-nav-item__icon sidebar-nav-item__icon--deposit-history" />
																	<div className="sidebar-nav-item__text">
																		<p>Deposit History</p>
																	</div>
																</NavLink>
															</li>
															<li>
																<NavLink
																	to={`${navList.withdrawalsHistory.path}/withdrawal-crypto`}
																	className={getNavLinkClass([
																		'/history/withdrawal-crypto',
																		'/history/withdrawal-fiat',
																	])}
																	activeClassName=""
																>
																	<div className="sidebar-nav-item__icon sidebar-nav-item__icon--withdrawal-history" />
																	<div className="sidebar-nav-item__text">
																		<p>Withdrawal History</p>
																	</div>
																</NavLink>
															</li>
														</ul>
													</div>
												</li>
												<li className="sidebar-nav-item__list-item sidebar-nav-item__list-item--has-children">
													<div
														className={
															location.pathname.includes('/account-settings') ||
															location.pathname.includes('/security-settings')
																? 'sidebar-nav-item__link active is-opened'
																: getNavLinkClass([
																		'/verification',
																		'/security-settings',
																		'/referrals',
																		'/security-settings/2fa',
																  ])
														}
														onClick={submenuParentClickHandler}
													>
														<div className="sidebar-nav-item__icon sidebar-nav-item__icon--account-settings" />
														<div className="sidebar-nav-item__text">
															<p>Account Settings</p>
														</div>
													</div>
													<div className="sidebar-nav-item__submenu">
														<ul>
															{!!userData && (
																<>
																	{statusNames[userData?.status_id] !== 'Verified' && (
																		<li>
																			<NavLink
																				to="/verification"
																				className={getNavLinkClass(['/verification'])}
																				activeClassName=""
																			>
																				<div className="sidebar-nav-item__icon sidebar-nav-item__icon--verification" />
																				<div className="sidebar-nav-item__text">
																					<p>Verification</p>
																				</div>
																			</NavLink>
																		</li>
																	)}
																</>
															)}
															<li>
																<NavLink
																	to="/account-settings"
																	className={getNavLinkClass(['/account-settings'])}
																	activeClassName=""
																>
																	<div className="sidebar-nav-item__icon sidebar-nav-item__icon--security-settings" />
																	<div className="sidebar-nav-item__text">
																		<p>Settings</p>
																	</div>
																</NavLink>
															</li>
															<li>
																<NavLink
																	to="/security-settings"
																	className={getNavLinkClass([
																		'/security-settings',
																		'/security-settings/2fa',
																	])}
																	activeClassName=""
																>
																	<div className="sidebar-nav-item__icon sidebar-nav-item__icon--security-settings" />
																	<div className="sidebar-nav-item__text">
																		<p>Security</p>
																	</div>
																</NavLink>
															</li>
															{/* <li>
															<NavLink
																to="/referrals"
																className={getNavLinkClass(['/referrals'])}
																activeClassName=""
															>
																<div className="sidebar-nav-item__icon sidebar-nav-item__icon--referrals" />
																<div className="sidebar-nav-item__text">
																	<p>Referrals</p>
																</div>
															</NavLink>
															</li> */}
														</ul>
													</div>
												</li>
												<li>
													<button
														onClick={() => {
															dispatch(logoutRequest({ history }));
														}}
														type="button"
														className="sidebar-nav-item__link"
													>
														<div className="sidebar-nav-item__icon sidebar-nav-item__icon--logout" />
														<div className="sidebar-nav-item__text">
															<p>Log Out</p>
														</div>
													</button>
												</li>
											</ul>
										)}
									</>
								)}
							</>
						)}
					</nav>
				</div>
			</div>

			{!minimizedSidebar && (
				<div className="footer-container">
					<a href="mailto:support@kaiserex.com" className="footer__support">
						support@kaiserex.com
					</a>
					<div className="footer-nav">
						<ul>
							<li>
								<a href={navList.termsOfUse.path} target="_blank" rel="noreferrer">
									Terms & Conditions
								</a>
							</li>
							<li>
								<a href={navList.privacyPolicy.path} target="_blank" rel="noreferrer">
									Privacy Policy
								</a>
							</li>
						</ul>
					</div>
				</div>
			)}
			<div
				className="burger"
				onClick={() => {
					const el = document.getElementById('main_nav');
					if (el) el.style.display = 'flex';
					// const el = Array.from(document.querySelectorAll('.sidebar-nav-item'));
					// el.forEach((element) => {
					// 	if (!(element instanceof HTMLElement)) {
					// 		throw new TypeError(`Expected an object of Type HTMLElement`);
					// 	}
					// 	// eslint-disable-next-line no-param-reassign
					// 	element.style.display = 'flex';
					// });
				}}
			/>
		</div>
	);
};

export default WalletSideBar;
