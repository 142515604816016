import { IStoreState } from 'redux/types';
import { createSelector } from '@reduxjs/toolkit';
import { ITransactionsStore } from './types';

export const getTransactions = (state: IStoreState): ITransactionsStore => state.transactions;

// 📌 Transaction history

export const getHistoryLoading = (state: IStoreState) => {
	return getTransactions(state).historyLoading;
};

// 📌 Transaction history - crypto

export const getCryptoHistory = (state: IStoreState) => {
	return getTransactions(state).cryptoHistory;
};

export const getCryptoHistoryList = (state: IStoreState) => {
	return getTransactions(state).cryptoHistory?.data;
};

export const getCryptoHistoryPageCount = (state: IStoreState) => {
	return getTransactions(state).cryptoHistory?.last_page || 1;
};
export const getCryptoHistoryTotal = (state: IStoreState) => {
	return getTransactions(state).cryptoHistory?.total || 1;
};

// 📌 Transaction history - fiat

export const getFiatHistory = (state: IStoreState) => {
	return getTransactions(state).fiatHistory;
};

export const getFiatHistoryList = (state: IStoreState) => {
	return getTransactions(state).fiatHistory?.data;
};

export const getFiatHistoryPageCount = (state: IStoreState) => {
	return getTransactions(state).fiatHistory?.last_page || 1;
};
export const getFiatHistoryTotal = (state: IStoreState) => {
	return getTransactions(state).fiatHistory?.total || 1;
};
// 📌 Transaction history - trade

export const getTradeHistory = (state: IStoreState) => {
	return getTransactions(state).tradeHistory;
};
export const getTradeHistoryLastPage = (state: IStoreState) => {
	return getTransactions(state).tradeHistory?.last_page || 1;
};
export const getTradeHistoryTotal = (state: IStoreState) => {
	return getTransactions(state).tradeHistory?.total || 1;
};
export const getTradeHistoryList = (state: IStoreState) => {
	return getTransactions(state).tradeHistory?.data;
};

export const getTradeHistoryPageCount = (state: IStoreState) => {
	return getTransactions(state).tradeHistory?.last_page || 1;
};

// 📌 Trade info
export const getTradeInfo = createSelector(
	[getTransactions],
	(transactions: ITransactionsStore) => transactions.tradeInfo,
);

export const getTradeInfoModal = createSelector(
	[getTransactions],
	(transactions: ITransactionsStore) => transactions.tradeInfoModal,
);

// 📌 Top Pair
export const getTopPair = createSelector(
	[getTransactions],
	(transactions: ITransactionsStore) => transactions.topPair,
);
// 📌 Limits by asset
export const getLimitsByAsset = createSelector(
	[getTransactions],
	(transactions: ITransactionsStore) => transactions.limits,
);

export const getInvoiceFiles = createSelector(
	[getTransactions],
	(transactions: ITransactionsStore) => transactions.invoiceFiles,
);

export const getInvoiceData = createSelector(
	[getTransactions],
	(transactions: ITransactionsStore) => transactions.invoiceData,
);

export const getQuoteError = createSelector(
	[getTransactions],
	(transactions: ITransactionsStore) => transactions.quotesError,
);

export const getTradeLoading = (state: IStoreState) => {
	return getTransactions(state).tradeRequestLoading;
};

export const getWithdrawalLoading = (state: IStoreState) => {
	return getTransactions(state).confirmWithdrawalLoading;
};
